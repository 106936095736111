:root {
  --ion-background-color: #FFF;
  --ion-background-color-rgb: 255,255,255;

  --ion-color-primary: #1E818D;
  --ion-color-primary-rgb: 30,129,141;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #1a727c;
  --ion-color-primary-tint: #358e98;

  --ion-color-secondary: #03D1AF;
  --ion-color-secondary-rgb: 3,209,175;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #03b89a;
  --ion-color-secondary-tint: #1cd6b7;

  --ion-color-tertiary: #38b2ac;
  --ion-color-tertiary-rgb: 56,178,172;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #319d97;
  --ion-color-tertiary-tint: #4cbab4;

  --ion-color-success: #48bb78;
  --ion-color-success-rgb: 72,187,120;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #3fa56a;
  --ion-color-success-tint: #5ac286;

  --ion-color-warning: #ecc94b;
  --ion-color-warning-rgb: 236,201,75;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #d0b142;
  --ion-color-warning-tint: #eece5d;

  --ion-color-danger: #f56565;
  --ion-color-danger-rgb: 245,101,101;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0,0,0;
  --ion-color-danger-shade: #d85959;
  --ion-color-danger-tint: #f67474;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,36,40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}
